export const CUSTOM_FIELDS_ITEMS_NR_LIMIT = {
  free: 0,
  gold: 10,
};
export const STORAGE_SIZE_LIMIT = {
  free: 300,
  gold: 3072,
};

export const MAX_NUMBER_SUBSCRIPTION_LICENSES = 100;
