import { useMemo, createContext, FC, useState, useEffect } from 'react';

import { CaptureContext, EventHint, Hub, User, Event, Breadcrumb } from '@sentry/types';
import { useConfig } from './hooks/useConfig';

type Primitive = number | string | boolean | bigint | symbol | null | undefined;
// type CaptureContext = Scope;
interface ISentry {
  captureException: (exception: any, captureContext?: CaptureContext) => ReturnType<Hub['captureException']>;
  captureEvent: (event: Event, hint?: EventHint | undefined) => string;
  captureMessage: (message: string, captureContext?: CaptureContext) => string;
  setTag: (key: string, value: Primitive) => void;
  setUser: (user: User | null) => void;
  setContext: (
    name: string,
    context: {
      [key: string]: any;
    } | null,
  ) => void;
  addBreadcrumb: (breadcrumb: Breadcrumb) => void;
}

export type SentryProviderType = {
  sentry?: ISentry;
};

export const SentryContext = createContext<SentryProviderType>({});
interface Props {
  children: React.ReactNode;
}

export const SentryProvider: FC<Props> = ({ children }) => {
  const { sentryEnabledReply, sentryEnabled, sentryDns, sentryEnv, sentryTracesSampleRate } = useConfig();
  const [sentry, setSentry] = useState<ISentry>({
    captureException: () => '',
    captureEvent: () => '',
    captureMessage: () => '',
    setTag: () => {},
    setUser: () => {},
    setContext: () => {},
    addBreadcrumb: (breadcrumb: Breadcrumb) => {},
  });
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const SentryContextValue = useMemo<SentryProviderType>(
    () => ({
      sentry,
    }),
    [sentry],
  );

  useEffect(() => {
    if (sentryEnabled) {
      if (!isInitialized) {
        setIsInitialized(true);
        import('@sentry/react').then(
          async ({
            init,
            captureException,
            captureMessage,
            setTag,
            setUser,
            setContext,
            captureEvent,
            addBreadcrumb,
          }) => {
            const { BrowserTracing } = await import('@sentry/tracing');
            const integrations: any[] = [new BrowserTracing()];

            if (sentryEnabledReply) {
              const { Replay } = await import('@sentry/replay');

              integrations.push(new Replay());
            }

            // captureEvent({ level: 'info' });

            setSentry({
              captureException,
              captureMessage,
              setTag,
              setUser,
              setContext,
              captureEvent,
              addBreadcrumb,
            });

            init({
              dsn: sentryDns,
              integrations,
              tracesSampleRate: parseFloat(sentryTracesSampleRate || '0.5'),
              environment: sentryEnv,
              replaysOnErrorSampleRate: 0.5,
            });
          },
        );
      }
    }
  }, [isInitialized, sentryEnabled, sentryEnabledReply, sentryEnv, sentryTracesSampleRate, sentryDns]);

  return <SentryContext.Provider value={SentryContextValue}>{children}</SentryContext.Provider>;
};

export default SentryProvider;
