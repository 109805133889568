import { useAuth0 } from '@auth0/auth0-react';
import {
  useMemo, useState, createContext, FC, ReactNode,
} from 'react';

export type SetupStatusContextType = {
  setupStatusState?:string,
  setUserSetupStatusState:(value:string)=>void
};

type Props = {
  children: ReactNode;
};

export const SetupStatusContext = createContext<SetupStatusContextType>({
  setUserSetupStatusState: () => {},
});

export const UserSetupStatusProvider:FC<Props> = ({ children }) => {
  const [setupStatusState, setUserSetupStatusState] = useState<string>();
  const { user } = useAuth0();
  const metaDataNamespace = useMemo(() => process.env.REACT_APP_AUTH0_METADATA_NAMESPACE
  || 'https://marinedatacloud.com/account/user_metadata', []);
  const auth0SetupStatus = useMemo(() => user?.[metaDataNamespace]?.setup_profile_status, [user]);

  const SetupStatusContextValue = useMemo(() => ({
    setupStatusState: setupStatusState || auth0SetupStatus,
    setUserSetupStatusState,
  }), [setupStatusState, setUserSetupStatusState, auth0SetupStatus]);

  return (
    <SetupStatusContext.Provider value={SetupStatusContextValue}>
      {children}
    </SetupStatusContext.Provider>
  );
};
