import React, { createContext } from 'react';
import { GraphQLClient } from 'graphql-request';

export type GraphQLClientState = {
  graphQLClient: GraphQLClient;
};

type Props = {
  children: React.ReactNode;
  defaultState?: GraphQLClientState;
};

export type GraphQLClientProviderState = GraphQLClientState | null;

export const GraphQLClientContext = createContext<GraphQLClientProviderState>(null);

const apiUrl: string = `${process.env.REACT_APP_MDC_API_HOST_URL}/graphql`;

const initialState: GraphQLClientState = {
  graphQLClient: new GraphQLClient(apiUrl),
};

// eslint-disable-next-line arrow-body-style
export const GraphQLClientProvider = ({ children, defaultState }: Props) => (
  <GraphQLClientContext.Provider value={defaultState || initialState}>{children}</GraphQLClientContext.Provider>
);
