import React, { useMemo, createContext, FC } from 'react';
import { useLoadScript } from '@react-google-maps/api';

export type GoogleMapProviderType = {
  isLoadedMapLibrary?: boolean;
  googleMapApiKey?: string;
};

export const GoogleMapContext = createContext<GoogleMapProviderType>({});
type TLibrary = 'places' | 'drawing' | 'geometry' | 'localContext' | 'visualization';

interface Props {
  values: GoogleMapProviderType;
  children: React.ReactNode;
}

export const GoogleMapProvider: FC<Props> = ({ children, values }) => {
  const libraries = useMemo<TLibrary[]>(() => ['places'], []);

  const { isLoaded: isLoadedMapLibrary } = useLoadScript({
    googleMapsApiKey: values.googleMapApiKey || '',
    libraries,
    id: 'google-map-script',
  });

  const GoogleMapContextValue = useMemo<GoogleMapProviderType>(
    () => ({
      isLoadedMapLibrary,
      ...values,
    }),
    [values, isLoadedMapLibrary],
  );

  return <GoogleMapContext.Provider value={GoogleMapContextValue}>{children}</GoogleMapContext.Provider>;
};

export default GoogleMapProvider;
