import { useContext } from 'react';
import { SentryContext } from '../SentryProvider';

export function useSentry() {
  const contextValue = useContext(SentryContext);

  if (!contextValue) {
    throw new Error('Wrap your components tree with a SentryProvider component');
  }

  return contextValue;
}
