export const DEFAULT_DATE_FORMAT = 'DD/MM/Y';

export const DATE_FORMAT_OPTIONS = [
  {
    id: 1,
    value: 'DD/MM/Y',
    label: '14/09/2024',
  },
  {
    id: 2,
    value: 'Y-MM-DD',
    label: '2024-09-14',
  },
  {
    id: 3,
    value: 'D/M/Y',
    label: '14/9/2024',
  },
  {
    id: 4,
    value: 'D/M/YY',
    label: '14/9/24',
  },
  {
    id: 5,
    value: 'DD/MM/YY',
    label: '14/09/24',
  },
  {
    id: 6,
    value: 'D.M.YY',
    label: '14.9.24',
  },
  {
    id: 7,
    value: 'MMM D YYYY',
    label: 'Sep 14 2024',
  },
  {
    id: 8,
    value: 'MM/D/YYYY',
    label: '09/14/2024',
  },
];

export const FIRST_DAY_OF_WEEK_OPTIONS = [
  {
    value: '0',
    label: 'Sunday',
  },
  {
    value: '1',
    label: 'Monday',
  },
];

export const TIME_FORMAT_OPTIONS = [
  {
    id: 0,
    value: 'HH:mm',
    label: '01:01 - 23:59',
  },
  // {
  //   id: 1,
  //   value: 'H:i',
  //   label: '1:01 - 23:59',
  // },
  // {
  //   id: 2,
  //   value: 'h:mm A',
  //   label: '1:01 AM - 11:59 PM',
  // },
  // {
  //   id: 3,
  //   value: 'hh:m A',
  //   label: '01:01 AM - 11:59 PM',
  // },
];

export const UNITS_DATA_OPTIONS = [
  {
    id: 0,
    value: 'imperial',
    label: 'Imperial',
    description: '(e.g. foot (ft), gallon (gal), pound (lb), etc)',
  },
  {
    id: 1,
    value: 'metric',
    label: 'Metric',
    description: '(e.g. metre (m), litre (l), kilogram (kg), etc)',
  },
];

export const DECIMAL_SYMBOL_OPTIONS = [
  {
    id: 0,
    value: '.',
    label: '. (12.34)',
  },
  {
    id: 1,
    value: ',',
    label: ', (12,34)',
  },
];

export const DIGIT_GROUPING_SYMBOL_OPTIONS = [
  {
    id: 0,
    value: '.',
    label: '. (123.456.789)',
  },
  {
    id: 1,
    value: ',',
    label: ', (123,456,789)',
  },
];

export const BOAT_TYPE_OPTIONS = [
  {
    id: 0,
    value: 'sail',
    label: 'Sail',
  },
  {
    id: 1,
    value: 'power',
    label: 'Power',
  },
];

export const COCKPIT_LOCATION_OPTIONS = [
  {
    id: 0,
    value: 'aft',
    label: 'Aft',
  },
  {
    id: 1,
    value: 'center',
    label: 'Center',
  },
];

export const CONFIRM_OPTIONS = [
  {
    id: 0,
    value: true,
    label: 'Yes',
  },
  {
    id: 1,
    value: false,
    label: 'No',
  },
];

export const HULL_TYPE_OPTIONS = [
  {
    id: 0,
    value: 'monohull',
    label: 'Monohull',
  },
  {
    id: 1,
    value: 'catamaran',
    label: 'Catamaran',
  },
  {
    id: 2,
    value: 'trimaran',
    label: 'Trimaran',
  },
  {
    id: 3,
    value: 'pontoon',
    label: 'Pontoon',
  },
  {
    id: 4,
    value: 'other',
    label: 'Other',
  },
];

export const HULL_MATERIAL_OPTIONS = [
  {
    id: 0,
    value: 'fiberglass',
    label: 'Fiberglass',
  },
  {
    id: 1,
    value: 'wood',
    label: 'Wood',
  },
  {
    id: 2,
    value: 'aluminium',
    label: 'Aluminium',
  },
  {
    id: 3,
    value: 'iron_and_steel',
    label: 'Iron & Steel',
  },
  {
    id: 4,
    value: 'cupronickel',
    label: 'Cupronickel ',
  },
  {
    id: 5,
    value: 'ferrocement',
    label: 'Ferrocement ',
  },
  {
    id: 6,
    value: 'composite_material',
    label: 'Composite',
  },
  {
    id: 7,
    value: 'carbon_fiber',
    label: 'Carbon Fiber',
  },
  {
    id: 8,
    value: 'hypalon',
    label: 'Hypalon',
  },
  {
    id: 9,
    value: 'roplene',
    label: 'Roplene',
  },
  {
    id: 10,
    value: 'pvc',
    label: 'PVC',
  },
  {
    id: 11,
    value: 'other',
    label: 'Other',
  },
];

export const ACQUISITION_TYPE_OPTION = [
  {
    id: 0,
    value: 'new',
    label: 'New',
  },
  {
    id: 1,
    value: 'used',
    label: 'Used',
  },
];

export const ACQUISITION_PRICE_OPTIONS = [
  {
    id: 0,
    value: 'GBP',
    label: 'GBP',
    name: 'GBP',
  },
  {
    id: 1,
    value: 'EUR',
    label: 'EUR',
    name: 'EUR',
  },
  {
    id: 2,
    value: 'USD',
    label: 'USD',
    name: 'USD',
  },
  {
    id: 3,
    value: 'CAD',
    label: 'CAD',
    name: 'CAD',
  },
  {
    id: 4,
    value: 'AUD',
    label: 'AUD',
    name: 'AUD',
  },
];

export const ACQUISITION_VAT_OPTIONS = [
  {
    id: 0,
    value: true,
    label: 'Paid VAT',
  },
  {
    id: 1,
    value: false,
    label: 'Not paid VAT',
  },
];

export const GENDER_OPTIONS = [
  {
    id: 0,
    value: 'male',
    label: 'Male',
  },
  {
    id: 2,
    value: 'female',
    label: 'Female',
  },
  {
    id: 3,
    value: 'neutral',
    label: 'Other',
  },
  {
    id: 4,
    value: 'unknown',
    label: 'I prefer not to say',
  },
];

export const BATTERY_VOLTAGE_OPTIONS = [
  {
    id: 0,
    value: 12,
    label: '12V',
  },
  {
    id: 1,
    value: 24,
    label: '24V',
  },
  {
    id: 2,
    value: 48,
    label: '48V',
  },
];

export const RIGGING_OPTIONS = [
  {
    id: 0,
    value: 'Sloop',
    label: 'Sloop',
  },
  {
    id: 1,
    value: 'Fractional-sloop',
    label: 'Fractional-sloop',
  },
  {
    id: 2,
    value: 'Masthead-sloop',
    label: 'Masthead-sloop',
  },
  {
    id: 3,
    value: 'Cutter',
    label: 'Cutter',
  },
  {
    id: 4,
    value: 'Ketch',
    label: 'Ketch',
  },
  {
    id: 5,
    value: 'Schooner',
    label: 'Schooner',
  },
  {
    id: 6,
    value: 'Brig',
    label: 'Brig',
  },
  {
    id: 7,
    value: 'Gaff',
    label: 'Gaff',
  },
  {
    id: 8,
    value: 'Square',
    label: 'Square',
  },
  {
    id: 9,
    value: 'Unstayed',
    label: 'Unstayed',
  },
];

export const ENGINE_TYPE_OPTIONS = [
  {
    id: 0,
    value: 'Inboard',
    label: 'Inboard',
  },
  {
    id: 1,
    value: 'Outboard',
    label: 'Outboard',
  },
  {
    id: 2,
    value: 'Sterndrive (I/O)',
    label: 'Sterndrive (I/O)',
  },
  {
    id: 3,
    value: 'Jet',
    label: 'Jet',
  },
  {
    id: 4,
    value: 'Other',
    label: 'Other',
  },
];

export const ENGINE_DRIVE_OPTIONS = [
  {
    id: 0,
    value: 'D-drive',
    label: 'D-drive',
  },
  {
    id: 1,
    value: 'V-drive',
    label: 'V-drive',
  },
  {
    id: 2,
    value: 'Jet-drive',
    label: 'Jet-drive',
  },
  {
    id: 3,
    value: 'Surface-drive',
    label: 'Surface-drive',
  },
  {
    id: 4,
    value: 'Pod-drive',
    label: 'Pod-drive',
  },
  {
    id: 5,
    value: 'Other',
    label: 'Other',
  },
];

export const ENGINE_FUEL_OPTIONS = [
  {
    id: 0,
    value: 'Diesel',
    label: 'Diesel',
  },
  {
    id: 1,
    value: 'Gasoline',
    label: 'Gasoline',
  },
  {
    id: 2,
    value: 'Electric',
    label: 'Electric',
  },
  {
    id: 3,
    value: 'LPG',
    label: 'LPG',
  },
  {
    id: 4,
    value: 'Other',
    label: 'Other',
  },
];

export const ENGINE_POWER_TYPE_OPTIONS = [
  {
    id: 0,
    value: 'hp',
    label: 'Hp',
  },
  {
    id: 1,
    value: 'kw',
    label: 'Kw',
  },
];

export type ValueType = string | number | null | undefined;

export interface ISelectOption<TValue extends ValueType = ValueType> {
  id: string | number;
  value: TValue;
  label: string;
  disabled?: boolean;
  hint?: string;
}

export const LOG_TYPE_OPTIONS: ISelectOption<string>[] = [
  {
    id: '1',
    label: 'General',
    value: 'general',
  },
  {
    id: '2',
    label: 'Weather',
    value: 'weather',
  },
  {
    id: '3',
    label: 'Radio',
    value: 'radio',
  },
  {
    id: '4',
    label: 'Social',
    value: 'social',
  },
  {
    id: '5',
    label: 'Operations',
    value: 'usage',
  },
];

export const LOG_EVENT_OPTIONS: ISelectOption[] = [
  {
    id: '1',
    label: 'Routine',
    value: 'routine',
  },
  {
    id: '2',
    label: 'Course change',
    value: 'course',
  },
  {
    id: '3',
    label: 'Landing',
    value: 'landing',
  },
  {
    id: '4',
    label: 'Emergency',
    value: 'emergency',
  },
  {
    id: '5',
    label: 'Fault',
    value: 'fault',
  },
];

export const LOG_SKY_OPTIONS: ISelectOption[] = [
  {
    id: '0',
    label: '-',
    value: '0',
  },
  {
    id: '1',
    label: '0/8',
    value: '1',
  },
  {
    id: '2',
    label: '1/8',
    value: '3',
  },
  {
    id: '3',
    label: '1/4',
    value: '3',
  },
  {
    id: '4',
    label: '3/8',
    value: '4',
  },
  {
    id: '5',
    label: '1/2',
    value: '5',
  },
  {
    id: '6',
    label: '5/8',
    value: '6',
  },
  {
    id: '7',
    label: '3/4',
    value: '7',
  },
  {
    id: '8',
    label: '7/8',
    value: '8',
  },
  {
    id: '9',
    label: '8/8',
    value: '8',
  },
];

export const LOG_PRECIPITATION_OPTIONS: ISelectOption[] = [
  {
    id: '0',
    label: 'Drizzle',
    value: 'drizzle',
  },
  {
    id: '1',
    label: 'Rain',
    value: 'rain',
  },
  {
    id: '2',
    label: 'Sleet',
    value: 'sleet',
  },
  {
    id: '3',
    label: 'Snow',
    value: 'snow',
  },
  {
    id: '4',
    label: 'Graupel',
    value: 'graupel',
  },
  {
    id: '5',
    label: 'Hail',
    value: 'hail',
  },
];

export const LOG_SEA_STATE_OPTIONS: ISelectOption[] = [
  {
    id: '0',
    label: '1 bft',
    value: '1',
  },
  {
    id: '1',
    label: '2 bft',
    value: '2',
  },
  {
    id: '2',
    label: '3 bft',
    value: '3',
  },
  {
    id: '3',
    label: '4 bft',
    value: '4',
  },
  {
    id: '4',
    label: '5 bft',
    value: '5',
  },
  {
    id: '5',
    label: '6 bft',
    value: '6',
  },
  {
    id: '6',
    label: '7 bft',
    value: '7',
  },
  {
    id: '8',
    label: '8 bft',
    value: '8',
  },
  {
    id: '9',
    label: '9 bft',
    value: '9',
  },
  {
    id: '10',
    label: '10 bft',
    value: '10',
  },
  {
    id: '11',
    label: '11 bft',
    value: '11',
  },
];

export const TIME_UNIT: ISelectOption<string>[] = [
  {
    id: '0',
    value: 'd',
    label: 'Day',
  },
  {
    id: '1',
    value: 'w',
    label: 'Week',
  },
  {
    id: '2',
    value: 'm',
    label: 'Month',
  },
  {
    id: '3',
    value: 'y',
    label: 'Year',
  },
];

export const TIME_PERIOD_UNIT: ISelectOption<string>[] = [
  {
    id: '0',
    value: 'd',
    label: 'Daily',
  },
  {
    id: '1',
    value: 'w',
    label: 'Weekly',
  },
  {
    id: '2',
    value: 'm',
    label: 'Monthly',
  },
  {
    id: '3',
    value: 'q',
    label: 'Quarterly',
  },
  {
    id: '4',
    value: 'b',
    label: 'Biannually',
  },
  {
    id: '5',
    value: 'y',
    label: 'Annually',
  },
  {
    id: '6',
    value: 'c',
    label: 'Custom',
  },
];

export const TASK_PRIORITY: ISelectOption<number>[] = [
  {
    id: 0,
    value: 1,
    label: 'High',
  },
  {
    id: 1,
    value: 2,
    label: 'Medium',
  },
  {
    id: 2,
    value: 3,
    label: 'Low',
  },
];

export const TASK_STATUS: ISelectOption<string>[] = [
  {
    id: 0,
    value: 'not_started',
    label: 'Not Started',
  },
  {
    id: 1,
    value: 'in_progress',
    label: 'In progress',
  },
  {
    id: 2,
    value: 'done',
    label: 'Done',
  },
  {
    id: 3,
    value: 'cancelled',
    label: 'Cancelled',
  },
];

export const TASK_CATEGORY: ISelectOption<number>[] = [
  {
    id: 0,
    value: 1,
    label: 'General',
  },
  {
    id: 1,
    value: 2,
    label: 'Document',
  },
  {
    id: 2,
    value: 3,
    label: 'Inventory',
  },
];

export const INVENTORY_ITEM_UNIT: ISelectOption<string>[] = [
  {
    id: 0,
    value: 'pieces',
    label: 'Pieces',
  },
  {
    id: 1,
    value: 'weight',
    label: 'Weight',
  },
  {
    id: 2,
    value: 'volume',
    label: 'Volume',
  },
];

export const INVENTORY_ITEM_TYPE: ISelectOption<string>[] = [
  {
    id: 0,
    value: 'asset',
    label: 'Asset',
  },
  {
    id: 1,
    value: 'consumables',
    label: 'Consumable',
  },
];

export const INVENTORY_ITEM_PACKING: ISelectOption<string>[] = [
  {
    id: 0,
    value: 'tin_can',
    label: 'Tin can',
  },
  {
    id: 1,
    value: 'box',
    label: 'Box',
  },
  {
    id: 2,
    value: 'bulk',
    label: 'Bulk',
  },
  {
    id: 3,
    value: 'bottle',
    label: 'Bottle',
  },
];

export const VAT_TYPE_OPTION: ISelectOption<string>[] = [
  {
    id: 0,
    value: 'inclusive',
    label: 'Included',
  },
  {
    id: 1,
    value: 'exclusive',
    label: 'Excluded',
  },
];

export const COST_TYPE_OPTION: ISelectOption<string>[] = [
  {
    id: 0,
    value: 'goods',
    label: 'Goods',
  },
  {
    id: 1,
    value: 'services',
    label: 'Services',
  },
];

export const RECURRENCE_TYPE_OPTION: ISelectOption<string>[] = [
  {
    id: 0,
    value: 'or',
    label: 'OR',
  },
  {
    id: 1,
    value: 'and',
    label: 'AND',
  },
];

export const QUANTITY_ACTION_OPTION: ISelectOption<string>[] = [
  {
    id: 0,
    value: 'buy',
    label: 'Bought',
  },
  {
    id: 1,
    value: 'use',
    label: 'Used',
  },
];
