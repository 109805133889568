import { ConfigType } from 'providers';

export function getAppConfiguration(): ConfigType {
  return {
    googleMapApiKey: process.env.GOOGLE_MAPS_API_KEY,
    googleAnalyticId: process.env.NEXT_PUBLIC_GA_ID,
    sentryDns: process.env.SENTRY_DSN,
    sentryEnv: process.env.SENTRY_ENV,
    sentryEnabled: process.env.SENTRY_ENABLED === 'true',
    sentryTracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
    sentryAuthToken: process.env.SENTRY_AUTH_TOKEN,
    auth0LogoutReturnTo: process.env.REACT_APP_AUTH0_LOGOUT_RETURN_TO,
    mdcApiHost: process.env.REACT_APP_MDC_API_HOST_URL,
    mdcFullApiUrl: `${process.env.REACT_APP_MDC_API_HOST_URL}/api/v2/`,
    mdcGraphQlUrl: `${process.env.REACT_APP_MDC_API_HOST_URL}/graphql`,
    cmsFullApiUrl: `${process.env.CMS_API_HOST_URL}/api`,
    cmsGraphQlUrl: `${process.env.CMS_API_HOST_URL}/graphql`,
    auth0Domain: process.env?.REACT_APP_AUTH0_DOMAIN,
    auth0Audience: process.env?.REACT_APP_AUTH0_AUDIENCE,
    auth0ClientId: process.env?.REACT_APP_AUTH0_CLIENT_ID,
    theboatDbBlogUrl: process.env?.TBD_BLOG_URL,
    appCode: process.env.APP_CODE,
    meiliSearchApiKey: process.env.MEILI_SEARCH_API_KEY,
    meiliSearchHostUrl: process.env.MEILI_SEARCH_HOST_URL,
    zohoSalesIqWidgetCode: process.env.ZOHO_SALES_IQ_WIDGET_CODE,
    mdcLandingUrl: process.env.MARINE_DATA_CLOUD_LANDING,
    cloudFlareSkipToken: process.env.CLOUDFLARE_SKIP_TOKEN,
    theboatAppUrl: process.env.NEXT_PUBLIC_THEBOATAPP_URL,
    theboatLandingUrl: process.env.NEXT_PUBLIC_THEBOATAPP_LANDING_URL,
  };
}
